export const landingBanner = 'https://images.pexels.com/photos/238118/pexels-photo-238118.jpeg'

export const projectsDetails = [
    {
      url: "https://cdn.pixabay.com/photo/2016/12/11/08/01/coming-soon-1898936__340.jpg",
      title: "Interest Subsidies & Anti Money Laundring Web Application",
      text: "Web application for client and internal staff to help for tax calculations and other tax related features to automate the process.",
      roles: "Developed the Front End UI, Backend Integrations, Testing, DevOps. Incorporated Security aspects for Web application.",
      tech: "React, Javascript, Dotnet core, MongoDB, DevOps, Agile",
      isCert: false
    },
    {
      url: "https://www.southcarolinablues.com/web/public/resources/dc811698-822e-4443-8cfc-b887becc8f8d/app-shots-01.png",
      title: "Blue Cross Blue Shield of Minnesota",
      text: "HealthCare App which provides health care plan and health care info.",
      roles: "Senior Mobile Developer, Design, Developed / Implementation of the application from start using MVC Architecture.",
      tech: "HTML5, Ionic, Javascript, CSS3, iOS, Android",
      isCert: false  
    },
    {
      url: "https://www.53.com/content/dam/fifth-third/heroes/momentum-hp-hero-phones.png",
      title: "Fifth-Third Bank, Open Credit Card (OCC 53)",
      text: "Banking Application serving customers in personal, business, commerical areas.",
      roles: "Senior UI Engineer, Involved in Developing UI (Front End) and Backend integrations into the application.",
      tech: "HTML4, CSS2, jQuery, Apache Wicket, Java, JSP",
      isCert: false
    },
    {
      url: "https://eu.doosanequipment.com/media/download/1232",
      title: "Doosan Infracore Construction Equipment",
      text: "The intranet web application was developed using Interwoven (CMS) which's a B2C and B2B application / website. And the web application is mainly used to showcase region &amp; locale specific products to dealers and customers.",
      roles: "UI Engineer, Involved in Developing UI and using Teamsite, Livesite, MediaBin, Open deploy.",
      tech: "XHTML, CSS, JS, JQuery, Photoshop, XSTL, Interwoven (CMS), XML, Agile",
      isCert: false
    },
    {
      url: "https://cdn.pixabay.com/photo/2015/08/09/08/58/ebay-881309__340.jpg",
      title: "eBay PeopleX",
      text: "Responsive Web HR application portal to facilitate onboarding and employee, non-employee, contractor management.",
      roles: "Senior Developer, Developed UI, Integrating Endpoint, Requirement Gathering coordinating with Business Analysts and Designers, Involved in Performance enhancements and optimization.",
      tech: "HTML5, CSS3, LESS, Bootstrap, Angular2, Javascript, Node",
      isCert: false
    },
    {
      url: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Devops-toolchain.svg/1024px-Devops-toolchain.svg.png",
      title: "DevOps Tasks",
      text: "On-premis Infrastructure support with Deploying and troubleshooting applications running on VMware Tanzu",
      roles: "DevOps Engineer, Setup of Applications on Azure, Auth, Roles, Enable Azure SSO login to Grafana, Logs setup on Front-end Apps, Azure pipleline support, setup CI/CD via Azure, etc.",
      tech: "Azure Devops, Prometheus, Grafana, ELK, Docker, Terraform, Bash, Linux, Kubernetes",
      isCert: false
    },
    {
      url: "https://www.jackcola.org/wp-content/uploads/2012/01/image_2f6bd2641d3c34e704feb8a4e5021f72.png",
      title: "WhitePages",
      text: "Whitepages is a provider of contact information for people and businesses. Responsive Web Application using the latest technology and best coding practices and performance orientated will change and helps people and business.",
      roles: "Full Stack Developer, Involved in Developing UI, Writing Unit Tests & Functional Tests, Integrating Backend Endpoint.",
      tech: "Bootstrap, Angular.js, Jasmine, npm, grunt, karma, Springboot MVC, Java, Geb, spock, Git",
      isCert: false
    },
    {
      url: "https://cdn.pixabay.com/photo/2016/12/11/08/01/coming-soon-1898936__340.jpg",
      title: "IT&S Automation Testing",
      text: "As part of IT&S have been part of major project where handled the Middleware, Services (JVM, MQ) Integration automation testing",
      roles: "Automation Tester, Involved in Integration and Functional Testing.",
      tech: "HP UFT (MicroFocus), ALM, Java",
      isCert: false
    },
    {
      url: "https://ravikiranr.me/static/media/clear-co2.png",
      title: "Clear CO2",
      text: "Won 4th place out of 100 applicants from over 40 different countries with 50k USD grant. A platform to empower SMEs to profit from carbon accounting, buy from sustainable suppliers and reverse climate change.",
      roles: "Azure Solutions Architect and Developing the MVP for the pitch in the Hackathon.",
      tech: "Machine learning, Python, Azure, Next.js",
      isCert: false
    },
    {
      url: "https://images.credly.com/images/8b8ed108-e77d-4396-ac59-2504583b9d54/cka_from_cncfsite__281_29.png",
      title: "CKA: Certified Kubernetes Administrator",
      text: "Acquired skills, knowledge and competencies to perform the responsibilities of a Kubernetes Administrator. Possess proficiency in Application Lifecycle Management, Installation, Configuration & Validation, Core Concepts, Networking, Scheduling, Security, Cluster Maintenance, Logging / Monitoring, Storage, Troubleshooting",
      roles: "https://www.credly.com/badges/5c0cd43f-de56-42f0-8ea4-bee0021ee042/public_url",
      tech: null,
      isCert: true
    },
    {
      url: "https://images.credly.com/size/680x680/images/9e4096f6-038b-4c0a-ad60-832ef84cbf14/LF_logobadge.png",
      title: "Kubernetes Fundamentals",
      text: "Kubernetes Fundamentals badge possess a strong operating knowledge of Kubernetes, including how to deploy a containerized application, manipulating resources via the API, accessing the cluster, and configuring Secrets and ConfigMaps.",
      roles: "https://www.credly.com/badges/d0112922-0ff9-4410-92da-d8d1416541ca/public_url",
      tech: null,
      isCert: true
    },
    {
      url: "https://images.credly.com/images/6a254dad-77e5-4e71-8049-94e5c7a15981/azure-fundamentals-600x600.png",
      title: "Microsoft Certified: Azure Fundamentals",
      text: "Azure Fundamentals certification have demonstrated foundational level knowledge of cloud services and how those services are provided with Microsoft Azure.",
      roles: "https://www.credly.com/badges/ed949127-e6d6-4803-b7f5-7dd43b0150a1/public_url",
      tech: null,
      isCert: true
    }
];

export const menuList = [{
  name: "Resume",
  link: "https://documentcloud.adobe.com/link/track?uri=urn:aaid:scds:US:e7efac53-4e33-462e-a70b-11ad8073fac0"
},{
  name: "News & Tips",
  link: "https://twitter.com/marathonintech"
}, {
  name: "Contact",
  link: "mailto:rknmit@outlook.com"
}];