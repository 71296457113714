import React, { useState } from "react";

import {
  animated,
  useChain,
  useSpringRef,
  useSprings,
  useSpring,
  useTransition,
} from "react-spring";

import styled, { ThemeProvider } from "styled-components";

import { XCircle as XIcon } from "react-feather";

import { Box, Container, Typography, Flex, Grid, theme, Img } from "../ui";

import "../ui/molecules/global-styles/global.css";

import logo from '../images/MIT-trans.png'

import { projectsDetails, menuList, landingBanner } from "../data";

const grey = theme.colors.bg400;
const black = theme.colors.bg500;

const AnimatedFlex = styled(animated(Flex))``;
AnimatedFlex.defaultProps = {};

const AnimatedBanner = styled(animated(Flex))`
  cursor: pointer;
`;
AnimatedBanner.defaultProps = {
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  willChange: "transform, opacity",
};

const Title = styled(animated(Typography))``;
Title.defaultProps = {
  fontSize: 7,
  lineHeight: "42px",
  bg: "rgba(255, 255, 255, 0.5)",
  mx: 2,
  p: [2, null, 4],
  textAlign: "center",
  fontWeight: 2,
  overflow: "hidden",
};

const GridContainer = styled(animated(Grid))``;
GridContainer.defaultProps = {
  gridTemplateColumns: [
    "repeat(1, 1fr)",
    null,
    "repeat(2, 1fr)",
    "repeat(4, 1fr)",
  ],
  gridGap: [1, null, 2],
};

const AnimatedContainer = styled(animated(Grid))``;
AnimatedContainer.defaultProps = {
  gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
  gridGap: 1,
  borderRadius: 1,
  willChange: "width, height",
};

const TitleWrapper = styled(animated(Flex))``;
TitleWrapper.defaultProps = {
  color: "text100",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  width: 1,
  height: "100%",
  p: [1, null, 2],
  background: "rgba(0,0,0,0.6)",
  fontSize: [4, null, 5],
};

const AnimatedItem = styled(animated(Flex))`
  cursor: pointer;
`;
AnimatedItem.defaultProps = {
  width: "100%",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  justifyContent: "space-evenly",
  alignItems: "center",
  flexDirection: "column",
  height: [300, null, 450],
};

const AnimatedBox = styled(animated(Box))``;
AnimatedBox.defaultProps = {};

const TextWrapper = styled(animated(Flex))`
  overflow: hidden;
`;
TextWrapper.defaultProps = {
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "center",
  height: "100%",
  background: "#fff",
  fontSize: 1,
};

const MenuBar = styled(animated(Flex))`
  cursor: pointer;
  text-transform: uppercase;
`;

MenuBar.defaultProps = {
  fontSize: 4,
  alignItems: "center",
  background: black,
  p: 1,
  color: "#fff",
};

const Menu = styled(animated(Box))`
  overflow: hidden;
`;

Menu.defaultProps = {
  bg: "#fff",
  px: 2,
};

const menuItemHeight = 60;
const MenuItem = styled(animated(Flex))`
  box-sizing: border-box;
  cursor: pointer;
  transition: color 0.2s;
  :hover {
    color: blue;
  }
  :last-child {
    border-bottom: none;
  }
`;

MenuItem.defaultProps = {
  height: menuItemHeight,

  bg: "#fff",
  alignItems: "center",
  justifyContent: "center",
  borderBottom: "2px solid",
  borderColor: "bg100",
};

function App() {
  const [indexS, setIndexS] = useState(null);
  const [isDelayed, setIsDelayed] = useState(true);
  const [clicked, setClicked] = useState(false);

  const vhToPixel = (value) => `${(window.innerHeight * value) / 100}px`;
  const animatedBannerRef = useSpringRef();
  const animatedBannerProps = useSpring({
    ref: animatedBannerRef,
    height: clicked ? "100px" : vhToPixel(100),
    opacity: clicked ? 0.6 : 1,
    from: {
      height: vhToPixel(100),
      opacity: 1,
    },
  });

  const titleRef = useSpringRef();
  const titleProps = useSpring({
    ref: titleRef,
    opacity: clicked ? 0 : 1,
    transform: clicked ? "translateY(-100%)" : "translateY(0%)",
    transformOrigin: "0 0",
    from: {
      opacity: 0,
      transform: "translateY(-100%)",
    },
  });

  const animatedContainerProps = useSpring({
    padding: clicked ? 32 : 0,

    from: {
      padding: 0,
    },
  });

  const animatedItemsRef = useSpringRef();
  const transitions = useTransition(clicked ? projectsDetails : [], {
    // set trail and config values to 0 when clicked === false to prevent unfinished animation
    ref: animatedItemsRef,
    unique: true,
    opacity: 1,
    trail: clicked ? 500 / projectsDetails.length : 0,
    transform: "translateY(0px)",
    delay: isDelayed ? 250 : 0,
    from: {
      opacity: 0,
      transform: "translateY(100px)",
    },
    enter: {
      opacity: 1,
      transform: "translateY(10px)",
      height: clicked ? '100%' : '0%'
    },
    leave: {
      opacity: 0,
      transform: "translateY(100px)",
    },
    config: { duration: clicked ? 500 : 0 },
  });

  useChain(
    clicked
      ? [titleRef, animatedItemsRef, animatedBannerRef]
      : [animatedItemsRef, titleRef, animatedBannerRef],
    [0, 0.5]
  );

  const [open, setOpen] = useState(false);

  const springRef = useSpringRef();
  const { background, iconTransform, ...springProps } = useSpring({
    ref: springRef,
    background: open ? grey : black,
    iconTransform: open ? "rotate(0deg)" : "rotate(-45deg)",
    height: open ? `${menuItemHeight * menuList.length}px` : "0px",
    from: {
      background: black,
      iconTransform: "rotate(-45deg)",
      height: "0px",
    },
  });

  const springsRef = useSpringRef();
  const delayValue = 20;
  const springs = useSprings(
    menuList.length,
    menuList.map((item, i) => ({
      ref: springsRef,
      name: item.name,
      link: item.link,
      delay: open
        ? i * delayValue
        : menuList.length * delayValue - i * delayValue,
      opacity: open ? 1 : 0,
      x: open ? "0%" : "-100%",
      from: {
        opacity: 0,
        x: "-100%",
      },
    }))
  );

  useChain(
    open ? [springRef, springsRef] : [springsRef, springRef],
    open ? [0, 0.25] : [0, 0.75]
  );

  const LogoImg = styled(Img)`
    margin-left: auto;
  `;

  return (
    <ThemeProvider theme={theme}>
      <Box bg="bg500" minHeight="100vh">
        <MenuBar onClick={() => setOpen(!open)} style={{ background }}>
          <AnimatedBox height={36} style={{ transform: iconTransform }}>
            <XIcon size={36} />
          </AnimatedBox>
          <Box pl={2}>Menu</Box>
          <LogoImg src={logo} width="75px" height="auto" alt="logo" />
        </MenuBar>
        <Menu style={springProps}>
          {springs.map(({ x, ...props }, i) => (
            <MenuItem
              key={i}
              style={{
                transform: x.interpolate((x) => `translateX(${x})`),
                ...props,
              }}
              onClick={() => {
                window.open(props.link.animation.to, "_blank");
              }}
            >
              {props.name}
            </MenuItem>
          ))}
        </Menu>
        <AnimatedBanner
          style={animatedBannerProps}
          background={`url(${landingBanner})`}
          onClick={() => setClicked(!clicked)}
          alignItems="center"
          justifyContent="center"
        >
          <Flex flexDirection="column" justifyContent="center">
            <Title style={titleProps}>
              - I'm Ravi Kiran.
              <Typography fontSize={3} opacity={0.7} pt={3} lineHeight={2}>
                Web 2.0 &amp; Mobile Application Developer, Devops Enabler,
                AI &amp; Blockchain Learner.
              </Typography>
              <Typography fontSize={3} opacity={0.7} pt={1} lineHeight={2}>
                Click to preview projects, trainings, certifications.
              </Typography>
            </Title>
          </Flex>
        </AnimatedBanner>
        <Container>
          <GridContainer style={animatedContainerProps}>
            {transitions(({ opacity, transform, height }, item, key, index) => (
              <AnimatedItem
                onClick={() => {
                  setIndexS(index);
                  setIsDelayed(false);
                }}
                key={index}
              >
                <AnimatedItem
                  background={`url(${item.url}?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)`}
                  style={{ opacity, transform, height}}
                >
                  <TitleWrapper style={{ opacity: indexS === index ? 0 : 1 }}>
                    {item.title}
                  </TitleWrapper>
                </AnimatedItem>
                <TextWrapper
                  style={{ height: indexS === index ? "150%" : "0%" }}
                >
                  <AnimatedBox
                    style={{ opacity: indexS === index ? 1 : 0 }}
                    p={1}
                  >
                    <Typography fontSize={3} fontWeight={2} pb={2}>
                      {item.title}
                    </Typography>
                    <Typography>{item.text}</Typography>
                    <Typography><u>{item.isCert ? 'Links': 'Roles'}</u>:{' '}
                    {item.isCert ? <a target="_blank" rel="noreferrer" href={item.roles}>{item.roles}</a>:item.roles}</Typography>
                    {item.tech && <Typography><u>Tech Stack</u>: {item.tech}</Typography>}
                  </AnimatedBox>
                </TextWrapper>
              </AnimatedItem>
            ))}
          </GridContainer>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;
